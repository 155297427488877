import React from 'react';
import { useDispositivo } from '../Hooks/Dispositivo';
import './styles.css';

function Navbar() {
  const dispositivo = useDispositivo();
  if (dispositivo?.mobile) {
    return (
      <footer className={dispositivo?.ios === true ? 'm-navbar-ios' : ''}>
        <div className='m-navbar'>
          <div>
            <div className='m-navbar-item-link'>
              <a href='/'>Sobre</a>
            </div>
          </div>
          <div>
            <div className='m-navbar-item-link'>
              <a href='/aplicativo'>Aplicativo</a>
            </div>
          </div>
          <div>
            <div className='m-navbar-item-link'>
              <a href='/painel'>Painel Médico</a>
            </div>
          </div>
          <div>
            <div className='m-navbar-item-link'>
              <a
                href='https://api.whatsapp.com/send?phone=5547992622984'
                target='_blank'
                rel='noreferrer'
                className='texto-contato'
              >
                Entre em contato
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Navbar;
