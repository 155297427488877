import React from 'react';
import logo from '../../media/logo.svg';
import Divisor from '../Divisor/Divisor';
import { useDispositivo } from '../Hooks/Dispositivo';
import MenuSuperior from '../menuSuperior/MenuSuperior';
import Navbar from '../Navbar/Navbar';
import './styles.css';

function Index() {
  const dispositivo = useDispositivo();

  return (
    <>
      <MenuSuperior />
      <main className='m-main-index'>
        <div className='m-bloco m-bloco-primario'>
          <h4>Bem vindo ao</h4>
          <img
            alt='Logo Medgic'
            src={logo}
            width={dispositivo?.mobile ? '100%' : 622}
            height={dispositivo?.mobile ? 'auto' : 176}
          />
        </div>
        <Divisor>Nossa missão</Divisor>
        <div className='m-bloco m-bloco-primario'>
          <h1 style={{ margin: 'auto' }}>
            <span className='m-enfase'>Revolucionar</span> o sistema de saúde.
          </h1>
        </div>
        <Divisor>Nosso time</Divisor>
        <div className='m-bloco m-bloco-paragrafo'>
          <p className='m-paragrafo-index'>
            Nossa liderança é composta por profissionais experientes e
            apaixonados pela evolução, dispostos a revolucionar o sistema de
            saúde atual, trazendo expertise e inovação em tudo o que fazemos.
            <br />
            <br />
            Nosso time é formado por 1 gerente de projetos, 1 desenvolvedor e 1
            designer, onde cada integrante traz consigo qualidades únicas que
            são valiosamente usadas para trazer excelência, qualidade e
            segurança em tudo o que fazemos.
            <br />
            <br />
            Nós compartilhamos um propósito único e estamos trabalhando
            incansavelmente para revolucionar o sistema de saúde. E temos
            pressa.
          </p>
        </div>
        <Divisor>Sobre o Medgic</Divisor>
        <div className='m-bloco m-bloco-paragrafo'>
          <p className='m-paragrafo-index'>
            O Medgic permite ao paciente armazenar os seus exames e informações
            de saúde de uma maneira simples, podendo compartilhar com o seu
            médico de confiança e realizar interações com ele, criando uma ponte
            segura de comunicação.
            <br />
            <br />O paciente possui total controle sobre as suas informações de
            saúde, e pode gerencia-las como bem entender.
          </p>
        </div>
        <Divisor />
        <div className='m-bloco-final'>
          <button className='botao-primario'>
            <a
              href={dispositivo?.appStoreLink}
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Ir para o app
            </a>
          </button>
          <button className='botao-outline'>
            <a
              href='/painel'
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Sou um médico
            </a>
          </button>
        </div>
      </main>
      <Navbar />
    </>
  );
}

export default Index;
