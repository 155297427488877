import React from 'react';
import { detectarSistemaOperacional } from '../../funcoes';
import googlePlayBadge from '../../media/googlePlayBadge.png';
import mockup from '../../media/mockup-app.png';
import Navbar from '../Navbar/Navbar';
import MenuSuperior from '../menuSuperior/MenuSuperior';
import './styles.css';
import { useDispositivo } from '../Hooks/Dispositivo';

function Aplicativo() {
  const dispositivo = useDispositivo();

  return (
    <>
      <MenuSuperior />
      <main className='m-aplicativo-main-container'>
        <div className='p1-container'>
          <h1>
            Esteja <br /> no controle <br /> da sua <strong>saúde</strong>
          </h1>
          <p className='descricao'>
            Milhões de pessoas usam o Medgic para armazenar seus exames,
            atividades, informações de saúde e compartilhar com um médico de
            confiança de forma simples, segura e gratuita.
          </p>
          {window.innerWidth <= 768 && (
            <div className='p2-container' style={{ marginBottom: 10 }}>
              <img
                alt='Aplicativo medgic'
                src={mockup}
                height={712.95}
                width={355}
              />
            </div>
          )}
          <div className='botoes-container'>
            <a
              className='botao-primario'
              href={dispositivo?.mobile ? dispositivo?.appStoreLink : 'https://app.medgic.com.br'}
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Ir para o app
            </a>

            <a
              className='botao-outline'
              href='/painel'
              style={{ textDecoration: 'none', color: 'white' }}
            >
              Sou um médico
            </a>
          </div>
          {(!dispositivo?.mobile || window.innerWidth > 768) && (
            <div className='m-stores'>
              <a href='https://play.google.com/store/apps/details?id=com.medgic.app&hl=pt_BR'>
                <img
                  src={googlePlayBadge}
                  alt='Disponível no Google Play'
                  height={55}
                />
              </a>
              <a
                href='https://apps.apple.com/br/app/medgic/id6670186201?itscg=30200&itsct=apps_box_link&mttnsubad=6670186201'
                style={{ marginLeft: 23 }}
              >
                <img
                  src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br?size=250x83&amp;releaseDate=1719014400'
                  alt='Baixar na App Store'
                  height={55}
                />
              </a>
            </div>
          )}
        </div>
        {window.innerWidth > 768 && (
          <div className='p2-container'>
            <img
              alt='Aplicativo medgic'
              src={mockup}
              style={{ height: 'min(78.1vmin, 90%)' }}
            />
          </div>
        )}
      </main>
      <Navbar />
    </>
  );
}

export default Aplicativo;
