import React, { useEffect } from 'react';
import logo from '../../media/logo.svg';
import './styles.css';

function Links() {
  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute('content', 'rgba(249, 249, 249, 1)');

    return () =>
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute('content', '#ffffff');
  }, []);

  return (
    <main className='m-main-links'>
      <div className='m-links-container'>
        <div className='m-links-titulo-container'>
          <img alt='Logo Medgic' src={logo} height={48} width={163} />
          <p>
            A baixo você tem o link de todos os canais oficiais do Medgic
            juntamente com nosso e-mail e numero de telefone para contato
          </p>
        </div>
        <div className='m-links-botoes-container'>
          <div className='m-links-botao'>
            <a href='/sobre'>Sobre nós</a>
          </div>
          <div className='m-links-botao'>
            <a href='/aplicativo'>Aplicativo</a>
          </div>
          <div className='m-links-botao'>
            <a href='/painel'>Painel</a>
          </div>
          <div className='m-links-botao'>
            <a href='/'>Site oficial</a>
          </div>
          <div className='m-links-botao'>
            <a href='mailto:suporte@medgic.com.br'>E-mail</a>
          </div>
          <div className='m-links-botao'>
            <a
              href='https://api.whatsapp.com/send?phone=554792622984'
              target='_blank'
              rel='noreferrer'
            >
              Whatsapp
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Links;
