import React from 'react';
import mockup from '../../media/mockup-painel.png';
import MenuSuperior from '../menuSuperior/MenuSuperior';
import './styles.css';
import Navbar from '../Navbar/Navbar';

function Painel() {
  return (
    <>
      <MenuSuperior />
      <main className='painel-main'>
        <div style={{ padding: '10px' }}>
          <h1>
            Intuitivo, <strong>seguro</strong> e inovador.
          </h1>
          <p className='descricao'>
            O Medgic cria uma ponte segura entre você e seu paciente. Tenha
            acesso a exames, medicamentos e episódios de saúde do seu paciente
            de uma maneira segura e organizada.
          </p>
        </div>
        <div className='p3-container'>
          <img
            alt='Medgic profissionais'
            src={mockup}
            style={{ width: 'min(113.7vmin, 90%)' }}
          />
        </div>
        <div className='botoes-container-painel'>
          <a
            className='botao-primario'
            href='https://painel.medgic.com.br'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            Ir para o painel
          </a>

          <a
            className='botao-outline'
            href='/aplicativo'
            style={{ textDecoration: 'none', color: 'white' }}
          >
            Sou um paciente
          </a>
        </div>
      </main>
      <Navbar />
    </>
  );
}

export default Painel;
