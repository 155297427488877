import React from 'react';
import logo from '../../media/logo.svg';
import { useDispositivo } from '../Hooks/Dispositivo';
import './styles.css';

function VersaoMobile() {
  return (
    <>
      <header>
        <div className='menu-topo'>
          <img src={logo} height={24} width={84.4} alt='Logo Medgic' />
        </div>
      </header>
    </>
  );
}

function ItemLink({ link, children }) {
  const linkAtivo = window.location.pathname.includes(link);

  return (
    <a className={linkAtivo === true ? 'm-link-ativo' : ''} href={`/${link}`}>
      {children}
    </a>
  );
}

function MenuSuperior() {
  const dispositivo = useDispositivo();

  return dispositivo?.mobile ? (
    <VersaoMobile />
  ) : (
    <header>
      <div className='menu-topo'>
        <div>
          <img src={logo} height={24} width={84.4} alt='Logo Medgic' />
          <ItemLink link='sobre'>Sobre</ItemLink>
          <ItemLink link='aplicativo'>Aplicativo</ItemLink>
          <ItemLink link='painel'>Painel Médico</ItemLink>
        </div>
        <div>
          <a
            href='https://api.whatsapp.com/send?phone=5547992622984'
            target='_blank'
            rel='noreferrer'
            className='texto-contato'
          >
            Entre em contato
          </a>
        </div>
      </div>
    </header>
  );
}

export default MenuSuperior;
