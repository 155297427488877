import { createContext, useContext, useEffect, useState } from 'react';

export const Ctx = createContext();

export const DispositivoProvider = ({ children }) => {
  const [dispositivo, setDispositivo] = useState();

  function dispositivoMobile() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (
      /android/i.test(userAgent) ||
      (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    ) {
      return true;
    }

    return false;
  }

  function dispositivoIos() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    return false;
  }

  function aplicativoPwa() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return true;
    }

    return false;
  }

  function aplicativoWebview() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Detecta WebView em Android
    var isAndroidWebView =
      /wv|.0.0.0/.test(userAgent) && /Android/.test(userAgent);

    // Detecta WebView em iOS
    var isIOSWebView =
      /iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent);

    return isAndroidWebView || isIOSWebView;
  }

  function navegadorSafari() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) !== -1
    ) {
      return false;
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      return false;
    } else if (
      navigator.userAgent.indexOf('Chrome') !== -1 ||
      navigator.userAgent.indexOf('CriOS') !== -1
    ) {
      return false;
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return true;
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return false;
    } else if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      !!document.documentMode === true
    ) {
      //IF IE > 10
      return false;
    } else {
      return false;
    }
  }

  function returnAppStoreLink() {
    if (dispositivoMobile()) {
      return dispositivoIos()
        ? 'https://apps.apple.com/br/app/medgic/id6670186201?itscg=30200&itsct=apps_box_link&mttnsubad=6670186201'
        : 'https://play.google.com/store/apps/details?id=com.medgic.app&hl=pt_BR';
    } else return '/aplicativo';
  }

  useEffect(() => {
    setDispositivo({
      mobile: dispositivoMobile(),
      ios: dispositivoIos(),
      pwa: aplicativoPwa(),
      webview: aplicativoWebview(),
      androidNavBarHeight: window.androidNavBarHeight,
      statusBarHeight: window.statusBarHeight,
      safari: navegadorSafari(),
      appStoreLink: returnAppStoreLink(),
    });
  }, []);

  return <Ctx.Provider value={dispositivo}>{children}</Ctx.Provider>;
};

export const useDispositivo = () => {
  return useContext(Ctx);
};
