import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

/**
 * @param {string} children
 * @return {object}
 */
function Divisor({ children }) {
  return (
    <div className='m-divisor-container'>
      <span className='m-divisor-linha' />
      <p className='m-divisor-texto'>{children}</p>
    </div>
  );
}

Divisor.propTypes = {
  children: PropTypes.string,
};

export default Divisor;
