import './App.css';
import { Outlet, Route, Routes } from 'react-router-dom';
import Index from './componentes/Index/Index';
import Links from './componentes/Links/Links';
import { useEffect } from 'react';
import Aplicativo from './componentes/Aplicativo/Aplicativo';
import Painel from './componentes/Painel/Painel';

/**
 * @param {string} titulo
 * @param {node} children
 * @returns
 */
function Pagina({ titulo, children }) {
  useEffect(() => {
    if (titulo) {
      document.title = titulo;
    }
  }, [titulo]);

  return children;
}

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Outlet />}>
          <Route
            index
            element={
              <Pagina titulo='Seja bem vindo ao Medgic!'>
                <Index />
              </Pagina>
            }
          />
          <Route
            path='?fbclid=:fbclid'
            element={
              <Pagina titulo='Seja bem vindo ao Medgic!'>
                <Index />
              </Pagina>
            }
          />
          <Route
            path='sobre'
            element={
              <Pagina titulo='Seja bem vindo ao Medgic!'>
                <Index />
              </Pagina>
            }
          />
          <Route
            path='aplicativo'
            element={
              <Pagina titulo='Sobre o aplicativo • Medgic'>
                <Aplicativo />
              </Pagina>
            }
          />
          <Route
            path='painel'
            element={
              <Pagina titulo='Sobre o painel • Medgic'>
                <Painel />
              </Pagina>
            }
          />
          <Route
            path='links'
            element={
              <Pagina titulo='Links úteis • Medgic'>
                <Links />
              </Pagina>
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default App;
